import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SuccesPage, FailedPage, SelectProdPage, ProductPage } from "./pages";

class Routering extends Component {
  state = {};
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <SelectProdPage />
          </Route>
          <Route path="/success">
            <SuccesPage></SuccesPage>
          </Route>
          <Route path="/failed">
            <FailedPage></FailedPage>
          </Route>
          <Route path="/users">{"<Users />"}</Route>
          <Route exact path="/main">
            <ProductPage />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Routering;
