import { ProdCard, Header, Footer } from "../../component";
import { useEffect, useState } from "react";
import { getIdRecomandations } from "../../Remote";
import "./selectProdStyle.css";

export default function SelectProdPage() {
  const [urlParams, setUrlParams] = useState({
    recommendationId: "",
    firebaseId: "",
    token: "",
    serverKey: "",
    debug: "",
  });

  const [products, setProducts] = useState([]);

  const getParamsURL = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const recommendationId = urlParams.get("recommendationId");
    const firebaseId = urlParams.get("firebaseId");
    const token = urlParams.get("token");
    const serverKey = urlParams.get("serverKey");
    const debug = urlParams.get("debug");
    return { recommendationId, firebaseId, token, serverKey, debug };
  };

  const getData = (recommendationId, debug) => {
    getIdRecomandations(recommendationId, debug)
      .then((data) => {
        setProducts(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (products && products.length === 1) {
      const details = products[0];
      window.location.assign(
        `/main?firebaseId=${urlParams.firebaseId}&token=${urlParams.token}&serverKey=${urlParams.serverKey}&productId=${details.id}&debug=${urlParams.debug}`
      );
    }
  }, [products]);

  useEffect(() => {
    const params = getParamsURL();
    getData(params.recommendationId, params.debug);
    setUrlParams(params);
  }, []);

  const selectProduct = (details) => {
    window.location.assign(
      `/main?firebaseId=${urlParams.firebaseId}&token=${urlParams.token}&serverKey=${urlParams.serverKey}&productId=${details.id}&debug=${urlParams.debug}`
    );
  };

  return (
    <div className="select-prod--page">
      <div className="contentSelectProd">
        <div className="container">
          <h1
            style={{
              fontFamily: "TeleNeo",
              fontWeight: 800,
              fontSize: 28,
              fontHeight: 30,
            }}
          >
            Available products
          </h1>
          <div className="grid-container">
            {products.map((element) => (
              <ProdCard
                details={element}
                key={element.productCode}
                selectProduct={selectProduct}
              ></ProdCard>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
