import axios from "axios";

const recommender = axios.create({
  baseURL: `https://lbl-recommender.superdtaglb.cf/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getProduct = ({ productId, debug }) => {
  const url = debug === "true" ? `/debug/product-by-pid` : "/product-by-pid";
  return recommender.get(`${url}?id=${productId}`);
};

export const orderProduct = (body, token, debug) => {
  const url =
    debug === "true"
      ? `/debug/order-product?transactionToken=${token}`
      : `/order-product?transactionToken=${token}`;
  return recommender.post(url, body);
};

export const getIdRecomandations = (id, debug) => {
  console.log(id, debug);
  const url = debug === "true" ? `/debug/products-by-rid` : "/products-by-rid";
  return recommender.get(`${url}?id=${id}`);
};
