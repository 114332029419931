import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../component";

import "../../style/succesStyle.css";
import "../../style/general.css";

function FailedPage() {
  const [product, setPrduct] = useState({});

  useEffect(() => {
    const product = sessionStorage.getItem("product");
    setPrduct(JSON.parse(product));
  }, []);

  const imgName = (category) => {
    switch (category) {
      case "Offer":
        return "Offer.svg";
      case "Network boost":
        return "Boost.svg";
      case "Another offer":
        return "Another.svg";
      case "Crazy offer":
        return "Crazy.svg";
      default:
        return "Offer.svg";
    }
  };

  return (
    <div className="failure-page">
      <div
        className="container succesContainer"
        style={{ display: "block", paddingTop: 52, boxSizing: "border-box" }}
      >
        <div className="imgContainer">
          <img
            className="productIcon"
            src={`./assets/img/${imgName(product.category)}`}
            style={{ height: "100px", width: "100px", marginRight: 8 }}
          ></img>
          <img
            className="successIcon"
            src="./assets/img/Error.svg"
            alt="check"
          ></img>
        </div>
        <div className="title">
          <h1 style={{ fontSize: 28, textAlign: "center", margin: 0 }}>
            {product.name}
          </h1>
          <h4 className="color" style={{ marginTop: 12 }}>
            COULD NOT BE INSTALLED
          </h4>
        </div>
        <div>
          <span style={{ fontSize: 14 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </span>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default FailedPage;
