import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../component";
import "../../style/succesStyle.css";
import "../../style/general.css";

export default function SuccesPage() {
  const [product, setPrduct] = useState({});

  useEffect(() => {
    const product = sessionStorage.getItem("product");
    setPrduct(JSON.parse(product));
  }, []);

  const imgName = (category) => {
    switch (category) {
      case "Offer":
        return "Offer.svg";
      case "Network boost":
        return "Boost.svg";
      case "Another offer":
        return "Another.svg";
      case "Crazy offer":
        return "Crazy.svg";
      default:
        return "Offer.svg";
    }
  };
  return (
    <div className="succes-page">
      <div
        className="container succesContainer"
        style={{ display: "block", paddingTop: 52, boxSizing: "border-box" }}
      >
        <div className="imgContainer">
          <img
            className="productIcon"
            src={`./assets/img/${imgName(product.category)}`}
            style={{ height: "100px", width: "100px", marginRight: 8 }}
          ></img>
          <img
            className="successIcon"
            src="./assets/img/success.svg"
            alt="check"
          ></img>
        </div>
        <div className="title">
          <h1
            style={{
              fontSize: 28,
              textAlign: "center",
              margin: 0,
              fontFamily: "TeleNeo",
              fontSize: 28,
              fontWeight: 800,
            }}
          >
            {product.name}
          </h1>
          <h4
            className="color"
            style={{
              marginTop: 12,
              fontFamily: "TeleNeo",
              fontSize: 16,
              fontWeight: 800,
            }}
          >
            IS PROVIDED TO YOUR DEVICE NOW!
          </h4>
        </div>
        <div className="infContainer">
          <h4
            style={{
              marginTop: 60,
              color: "#999999",
              marginBottom: 0,
              fontFamily: "TeleNeo",
              fontSize: 16,
              fontWeight: 800,
            }}
          >
            The order has been placed for
          </h4>
          <hr
            style={{
              marginTop: 12,
              border: "0.1px solid #999999",
              height: 0,
              width: "100%",
            }}
          />
          <div className="contactSection">
            <h4>CONTRACT</h4>{" "}
            <div className="contactInfo">
              <img src="Group 187.svg"></img>
              <span>+49 (171) 643 21 68</span>
            </div>
          </div>
          <hr
            style={{
              marginTop: 20,
              border: "0.1px solid #999999",
              height: 0,
              width: "100%",
            }}
          />
          <p
            style={{
              fontFamily: "TeleNeo",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            *The subscription can be canceled{" "}
            <span className="color">here</span> at any time. <br />
            <span className="color"> *Terms and condition.</span>
          </p>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
