import React, { useEffect, useState } from "react";
/* Components */
import { Header, Footer, MyPopUp } from "../../component";
/*Utils*/
import {
  getProduct as getProductRemote,
  orderProduct as orderProductRemote,
} from "../../Remote";
/* Style */
import "../../style/mainPage.css";

export default function ProductPage() {
  const [product, setProduct] = useState(null);
  const [credentials, setCredentials] = useState({});
  const [switchButton, setSwitch] = useState(false);
  const [popUp, setPopUp] = useState({
    open: false,
    title: "err",
    message: "err",
    close: () => {},
  });
  console.log(product);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const firebaseId = urlParams.get("firebaseId");
    const token = urlParams.get("token");
    const productId = urlParams.get("productId");
    const serverKey = urlParams.get("serverKey");
    const debug = urlParams.get("debug");
    const params = {
      firebaseId,
      token,
      productId,
      serverKey,
      debug,
    };
    getProduct(params);
    setCredentials(params);
    const p = sessionStorage.getItem("p");
    if (p === "faile") window.location.assign("/failed");
    if (p === "succes") window.location.assign("/success");
  }, []);

  const getProduct = async (params) => {
    try {
      const result = await getProductRemote(params);
      console.log(result, "data");
      setProduct(result.data);
    } catch (err) {
      setPopUp({
        open: true,
        message: "The product cannot be displayed",
        title: "Error",
        close: hide,
      });
    }
  };

  const hide = () => {
    setPopUp({ open: false });
  };

  const orderProduct = async () => {
    sessionStorage.setItem("product", JSON.stringify(product));
    try {
      await orderProductRemote(
        {
          firebaseId: credentials.firebaseId,
          serverKey: credentials.serverKey,
          productId: credentials.productId,
        },
        credentials.token,
        credentials.debug
      );
      sessionStorage.setItem("p", "succes");
      window.location.assign(`/success`);
    } catch (errr) {
      sessionStorage.setItem("p", "faile");
      window.location.assign(`/failed`);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const imgName = (category) => {
    switch (category) {
      case "Offer":
        return "Offer_white.svg";
      case "Network boost":
        return "Boost_white.svg";
      case "Another offer":
        return "Another_white.svg";
      case "Crazy offer":
        return "Crazy_white.svg";
      default:
        return "Offer_white.svg";
    }
  };
  const tagImg = {
    "high accuracy": "Efficiency.svg",
    security: "Security.svg",
    speed: "Speed.svg",
    efficency: "Efficiency.svg",
    "low latency": "LowLatency.svg",
  };

  const setTrial = (product) => {
    if (product && product.productOfferingPrice.length > 1) return "multiple";
    switch (product && product.productOfferingPrice[0].priceType) {
      case "recurringCharge":
        return "pay monthly";
      case "onetimeCharge":
        return "pay once";
      default:
        return "other";
    }
  };

  const getPrice = () =>
    setTrial(product) === "pay monthly" ? (
      <>
        <h2 id="totalCharges">Total charges</h2>
        <div className="priceSection">
          <h4 className="priceLabel">Setup charges</h4>
          <span className="price">0€</span>
        </div>
        <div className="priceSection" style={{ marginTop: 12 }}>
          <h4 className="priceLabel">Monthly charge</h4>
          <span className="price">
            {product.productOfferingPrice[0].price.taxIncludedAmount.value}
            €/month
          </span>
        </div>
      </>
    ) : (
      <>
        <h2 id="totalCharges">Total charges</h2>
        <div className="priceSection">
          <h4 className="priceLabel">Setup charges</h4>
          <span className="price">
            {product.productOfferingPrice[0].price.taxIncludedAmount.value}€
          </span>
        </div>
        <div className="priceSection" style={{ marginTop: 12 }}>
          <h4 className="priceLabel">Monthly charge</h4>
          <span className="price">0€/month</span>
        </div>
      </>
    );

  return (
    <div>
      <MyPopUp
        open={popUp.open}
        onClose={popUp.close}
        title={popUp.title}
        message={popUp.message}
      ></MyPopUp>
      <div className="titleSection">
        <img
          src={`./assets/img/${
            product && product.ocbProperties
              ? imgName(product.ocbProperties.productCategory)
              : ""
          }`}
          alt=""
          style={{ marginTop: 24, width: 60, height: 61 }}
        ></img>
        <h1 id="productName">{product ? product.name : ""}</h1>
        <span id="productDescription" style={{ marginBottom: 16 }}>
          {product && product.ocbProperties
            ? product.ocbProperties.productCategory
            : ""}
        </span>
        <span id="regionAndTrial">
          {`available in ${
            product && product.ocbProperties ? product.ocbProperties.region : ""
          } | `}{" "}
          <span style={{ fontWeight: 800 }}>{`${setTrial(product)}`}</span>
        </span>
        <div className="product-page--tag-container">
          {product &&
            product.ocbProperties &&
            product.ocbProperties.tags.map((elem, index) => (
              <div className="product-page--tag" key={`tag${index}`}>
                <img
                  src={`./assets/img/${tagImg[elem]}`}
                  style={{ height: "14px", width: "14px", marginRight: 8 }}
                ></img>
                <span className="product-page--tag-name">{elem}</span>
              </div>
            ))}
        </div>
      </div>
      <div className="container">
        {product && getPrice()}
        <div className="taxesInfo">
          <p>* All taxes are included in the displayed prices.</p>
          <p>
            * The details and settings can be displayed by clicking the
            following <span className="color">link. </span>
          </p>
          <hr
            style={{ marginTop: 20, border: "0.1px solid #999999", height: 0 }}
          />

          <div className="contactSection">
            <h4>CONTRACT</h4>{" "}
            <div className="contactInfo">
              <img src="Group 187.svg"></img>
              <span>+49 (171) 643 21 68</span>
            </div>
          </div>
        </div>

        <div className="contactInfo">
          Identification details registerd with this Telekom number will be used
          in the booking process
        </div>
        <hr
          style={{
            marginTop: 20,
            border: "0.1px solid #999999",
            height: 0,
            width: "100%",
          }}
        />

        <div className="agreementSection">
          <div className="switchInfo">
            <div className="agreeSwitch">
              <label
                class="switch"
                onClick={(event) => {
                  setSwitch(event.target.checked);
                }}
              >
                <input type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
            <div className="info">
              I acknowledge that completion of the process adds above paid
              service option to above contract. I agree to Telekom processing
              existing personal information from my contract for this purpose.
            </div>
          </div>
          {product && product.ocbProperties && product.ocbProperties.hasTrial && (
            <button
              variant="contained"
              className="freeTrialButton buttonSize"
              onClick={() => orderProduct()}
            >
              FREE TRIAL
            </button>
          )}
          <button
            variant="contained"
            className={
              product && product.ocbProperties && product.ocbProperties.hasTrial
                ? "subButton buttonSize"
                : "subButton buttonSize  mt-28"
            }
            disabled={!switchButton}
            style={
              !switchButton
                ? { color: "Black", backgroundColor: "#E0E0E0" }
                : { color: "White", backgroundColor: "#e20074" }
            }
            onClick={() => orderProduct()}
          >
            Subscribe
          </button>
          <button className="cancelButton buttonSize" onClick={goBack}>
            CANCEL
          </button>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
