import "./prodCard.css";

export default function ProdCard({ details, selectProduct }) {
  const selectEvent = () => {
    selectProduct(details);
  };

  console.log(details);

  const tagImg = {
    "high accuracy": "Efficiency.svg",
    security: "Security.svg",
    speed: "Speed.svg",
    efficency: "Efficiency.svg",
    "low latency": "LowLatency.svg",
  };

  const setTrial = (product) => {
    if (product.productOfferingPrice.length > 1) return "multiple";
    switch (product.productOfferingPrice[0].priceType) {
      case "recurringCharge":
        return "pay monthly";
      case "onetimeCharge":
        return "pay once";
      default:
        return "other";
    }
  };

  const imgName = (category) => {
    switch (category) {
      case "Offer":
        return "Offer.svg";
      case "Network boost":
        return "Boost.svg";
      case "Another offer":
        return "Another.svg";
      case "Crazy offer":
        return "Crazy.svg";
      default:
        return "Offer_white.svg";
    }
  };

  return (
    <div className="product-card--container" onClick={selectEvent}>
      <div className="product-card--info">
        <img
          src={`./assets/img/${imgName(details.ocbProperties.productCategory)}`}
          style={{ height: "32px", width: "32px", marginRight: 8 }}
        ></img>
        <div className="product-card--info-right">
          <div>
            <span className="product-card--category">
              {details.ocbProperties.productCategory}
            </span>
            <span className="product-card--region">{`avaible ${details.ocbProperties.region} | `}</span>
            <span className="product-card--trial">{setTrial(details)}</span>
          </div>
          <span className="product-card--name">{details.name}</span>
        </div>
      </div>
      <div className="product-card--tag-container">
        {details.ocbProperties &&
          details.ocbProperties.tags.map((elem, index) => (
            <div className="product-card--tag" key={`tag${index}`}>
              <img
                src={`./assets/img/${tagImg[elem]}`}
                style={{ height: "12px", width: "12px", marginRight: 8 }}
              ></img>
              <span className="product-card--tag-name">{elem}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
