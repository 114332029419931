import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
const styles = makeStyles({
  avatar: {
    backgroundColor: "red",
    color: "red",
  },
  button: {
    color: "#e20074",
    "&:focus": {
      color: "#e20074",
    },
    "&:text": {
      color: "#e20074",
    },
  },
});

export default function MyPopUp(props) {
  const classes = styles();
  const { onClose, selectedValue, open, title, message } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disableFocusRipple={false}
          disableRipple={true}
          onClick={handleClose}
          style={{ color: "#e20074" }}
          // classes={classes.button}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
