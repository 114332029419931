import React from "react";
import "../../style/footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="footerContainer">© Telekom Deutschland GmbH</div>
    </footer>
  );
}
